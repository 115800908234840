<template>
  <txn-stats-skeleton v-if="loading" />
  <error v-else-if="error" :error-msg="error"/>
  <template v-else>
   <section v-if="!txnDetails.successful" class="pb-1"> 
     <div  v-for="(failedMessage, index) in txnDetails?.failed_messages" :key="index"  class="border border-danger rounded px-3 py-1">
       <div class="d-flex flex-row gap-3 align-items-center">
         <i class="bi bi-x-circle-fill text-danger fs-2"></i>
         <div class="d-flex flex-column justify-content-center">
           <div class="fw-bold">{{ camelCaseToNormalTextWithCaps(failedMessage.opName) }}</div>
           <div class="small">{{ failedMessage.description }}</div>
         </div>
       </div>
     </div>
   </section>
   <div class="card">
      <div class="card-body">
        <ul class="list-group gap-2">
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-hash me-3"></i
              >{{ $t("transaction_stats.ledger_hash") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span class="align-self-center small font-monospace">{{
                txnDetails?.ledger.hash ?? "-"
              }}</span
              ><router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(txnDetails?.ledger.hash ?? '-')"
                >{{ $t("transaction_stats.copy") }}</router-link
              >
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-circle me-3"></i
              >{{ $t("transaction_stats.status") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span
                v-if="txnDetails.successful"
                class="
                  badge
                  bg-success bg-opacity-25
                  text-success
                  align-self-center
                "
                >{{ $t("transaction_stats.success") }}
                <i class="bi bi-check-circle-fill ms-1"></i
              ></span>
              <span
                v-if="!txnDetails.successful"
                class="
                  badge
                  bg-danger bg-opacity-25
                  text-danger
                  align-self-center
                "
                >{{ $t("transaction_stats.failed") }}
                <i class="bi bi-x-circle-fill ms-1"></i
              ></span>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-card-list me-3"></i
              >{{ $t("transaction_stats.ledger") }}
            </div>
            <div class="w-75 py-2 px-3">
              <router-link
                v-if="txnDetails?.ledger.sequence"
                class="
                  align-self-center
                  small
                  font-monospace
                  text-decoration-none
                "
                :to="{
                  name: 'LedgerDetailsRoute',
                  params: { ledger_sequence: txnDetails?.ledger.sequence },
                }"
                >{{ txnDetails?.ledger.sequence }}</router-link
              >
              <div v-else>-</div>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-person-square me-3"></i
              >{{ $t("transaction_stats.source_account") }}
            </div>
            <div class="w-75 py-2 px-3">
              <router-link
                class="
                  align-self-center
                  small
                  font-monospace
                  text-decoration-none
                "
                :to="{
                  name: 'AccountDetailsRoute',
                  params: {
                    acc_id: txnDetails.source_account,
                  },
                }"
                >{{ txnDetails.source_account }}</router-link
              ><router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(txnDetails.source_account)"
                >{{ $t("transaction_stats.copy") }}</router-link
              >
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-tag-fill me-3"></i
              >{{ $t("transaction_stats.label") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span
                class="
                  text-decoration-none
                  font-monospace
                  fw-bold
                  text-dark
                  small
                "
              >
                {{
                  accountLabels.data[txnDetails.source_account]?.label?.name ??
                  "-"
                }}
              </span>
              <i
                v-tooltip
                class="bi ms-5"
                :class="labelData(txnDetails.source_account)?.icon"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :data-bs-original-title="
                  labelData(txnDetails.source_account)?.tooltip
                "
              ></i>
              <router-link
                :to="{
                  name: 'DirectoryUpdateRoute',
                  query: {
                    account: txnDetails.source_account,
                  },
                }"
                class="float-end text-decoration-none"
                >{{ $t("transaction_stats.update") }}
              </router-link>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-hash me-3"></i
              >{{ $t("transaction_stats.source_account_sequence") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span
                class="
                  align-self-center
                  small
                  font-monospace
                  text-decoration-none
                "
              >
                {{ txnDetails.source_account_sequence }}</span
              >
              <router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(txnDetails.source_account_sequence)"
                >{{ $t("transaction_stats.copy") }}</router-link
              >
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-cash me-3"></i
              >{{ $t("transaction_stats.fee_account") }}
            </div>
            <div class="w-75 py-2 px-3">
              <router-link
                class="
                  align-self-center
                  small
                  font-monospace
                  text-decoration-none
                "
                :to="{
                  name: 'AccountDetailsRoute',
                  params: {
                    acc_id: txnDetails.fee_account,
                  },
                }"
                >{{ txnDetails.fee_account }}</router-link
              >
              <span
                v-if="txnDetails.fee_account === txnDetails.source_account"
                v-tooltip
                class="badge rounded-pill bg-secondary ms-2"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                :data-bs-original-title="
                  $t('transaction_stats.fee_account_matches_source')
                "
                >{{ $t("transaction_stats.matching") }}</span
              >
              <router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(txnDetails.fee_account)"
                >{{ $t("transaction_stats.copy") }}</router-link
              >
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-body-text me-3"></i
              >{{ $t("transaction_stats.memo") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span
                v-tooltip
                class="badge bg-secondary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title=""
                :data-bs-original-title="
                  $t('transaction_stats.links_are_scams')
                "
                >{{ txnDetails.memo ?? 'No memo' }}</span
              >
              <router-link
                to="#"
                class="float-end text-decoration-none"
                @click="copy(txnDetails.memo)"
                >{{ $t("transaction_stats.copy") }}</router-link
              >
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-clock me-3"></i>
              {{ $t("transaction_stats.created_at") }}
            </div>
            <div class="w-75 py-2 px-3">
              <span class="align-self-center small font-monospace">
                {{formatDate(txnDetails.created_at) + " · " + formatDateElapsedTime(txnDetails.created_at)}}
              </span>
            </div>
          </li>
          <li v-if="get(showEvents, 'events.0.topics.0') == 'transfer'" class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
                <i class="bi bi-body-text me-3"></i>
                {{ $t("transaction_stats.transferred_tokens") }}
            </div>
            <div class="w-75 py-2 px-3">
              <i class="bi bi-caret-right-fill"></i>
                From
                <span class="badge bg-body-tertiary">
                    <router-link
                      v-middle-ellipsis="{ size: 6, text: get(showEvents, 'events.0.topics.1')}"
                      :to="{
                        name: 'AccountDetailsRoute',
                        params: {
                          acc_id: get(showEvents, 'events.0.topics.1'),
                        },
                      }"
                      class="fw-bold text-decoration-none text-dark font-monospace small"
                    />
                </span>
                <i class="bi bi-caret-right-fill"></i>
                To
                <span class="badge bg-body-tertiary">
                  <router-link
                    v-middle-ellipsis="{ size: 6, text: get(showEvents, 'events.0.topics.2')}"
                    :to="{
                      name: 'AccountDetailsRoute',
                      params: {
                        acc_id: get(showEvents, 'events.0.topics.2'),
                      },
                    }"
                    class="fw-bold text-decoration-none text-dark font-monospace small"
                    :data-bs-original-title="$t('txn_summary.source_account')"
                  />
                </span>
                Tokens <span class="badge bg-primary-subtle text-dark">{{get(showEvents, 'events.0.data')}}</span>
              </div>
            </li>
          <li v-if="txnDetails.contract_id" class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
                <i class="bi bi-clock me-3"></i>
                {{ $t("transaction_stats.contract_id") }}
            </div>
            <div class="w-75 py-2 px-3">
              <div class="form-floating">
                <span class="small font-monospace">
                <router-link
                    :to="{
                        name: 'ContractDetailsRoute',
                        params: {
                          contract_id: txnDetails.contract_id,
                        },
                    }"
                    class="text-decoration-none">
                    {{ txnDetails.contract_id }}
                </router-link>
                </span>
              </div>
            </div>
          </li>
          <li class="list-group-item d-flex p-0 border-0">
            <div class="w-25 py-2 px-3 bg-body-tertiary rounded">
              <i class="bi bi-clock me-3"></i>
              {{ $t("transaction_stats.operations") }}
            </div>
            <div class="w-75 py-2 px-3">
              <div class="row g-2 small">
                <div class="col-lg-4">
                  <div class="d-flex p-0 border-0">
                    <div class="w-50 py-1 px-3 text-muted">
                      {{ $t("transaction_stats.operations") }}
                    </div>
                    <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                      {{ txnDetails.operation_count }} / 100
                    </div>
                  </div>
                  <div class="p-0 border-0 d-flex">
                    <div class="py-2 px-3 align-self-center w-100">
                      <div class="progress" style="height: 12px">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          :style="`width: ${txnDetails.operation_count}%`"
                          :aria-valuenow="txnDetails.operation_count"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div v-if="txnDetails.valid_after" class="d-flex p-0 border-0">
                    <div class="w-50 py-1 px-3 text-muted">
                      {{ $t("transaction_stats.valid_from") }}
                    </div>
                    <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                      {{ formatDate(txnDetails.valid_after) }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="d-flex p-0 border-0">
                    <div class="w-50 py-1 px-3 text-muted">
                      {{ $t("transaction_stats.fee") }}
                    </div>
                    <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                      {{
                        (0.00001 * txnDetails.fee_charged / 100).toFixed(5) +
                        $t("transaction_stats.xlm")
                      }}
                    </div>
                  </div>
                  <div class="d-flex p-0 border-0">
                    <div class="w-50 py-1 px-3 text-muted">
                      {{ $t("transaction_stats.max_fee") }}
                    </div>
                    <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                      {{
                        (0.00001 * txnDetails.max_fee / 100).toFixed(5) +
                        $t("transaction_stats.xlm")
                      }}
                    </div>
                  </div>
                  <div v-if="txnDetails.valid_before" class="d-flex p-0 border-0">
                    <div class="w-50 py-1 px-3 text-muted">
                      {{ $t("transaction_stats.valid_to") }}
                    </div>
                    <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                      {{ formatDate(txnDetails.valid_before) }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div
                    v-tooltip
                    class="d-flex p-0 border-0 list-group-item-action"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    :data-bs-original-title="
                      $t('transaction_stats.aggregated_xlm_value')
                    "
                  >
                    <div class="w-50 py-1 px-3 text-muted">
                      {{ $t("transaction_stats.xlm") }}
                    </div>
                    <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                      {{ checkTxnValue(txnDetails.hash, "xlm_value") }}
                    </div>
                  </div>
                  <div
                    v-tooltip
                    class="d-flex p-0 border-0 list-group-item-action"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    :data-bs-original-title="
                      $t('transaction_stats.aggregated_btc_value')
                    "
                  >
                    <div class="w-50 py-1 px-3 text-muted">
                      {{ $t("transaction_stats.btc") }}
                    </div>
                    <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                      {{ checkTxnValue(txnDetails.hash, "btc_value") }}
                    </div>
                  </div>
                  <div
                    v-tooltip
                    class="d-flex p-0 border-0 list-group-item-action"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    :data-bs-original-title="
                      $t('transaction_stats.aggregated_usd_value')
                    "
                  >
                    <div class="w-50 py-1 px-3 text-muted">
                      {{ $t("transaction_stats.usd") }}
                    </div>
                    <div class="w-50 py-1 px-3 fw-bold font-monospace text-end">
                      {{ checkTxnValue(txnDetails.hash, "usd_value") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <contract-and-transaction-details-tabs />
    <div class="tab-content">
      <div id="nav-operations" class="tab-pane fade show active" role="tabpanel" aria-labelledby="nav-operations-tab">
        <template v-for="(op, idx) in txnDetails.operations" :key="idx">
          <operation-info :operation-details="op" :idx="idx" />
        </template>
        <loading v-if="loading" />
        <error v-else-if="error" />
      </div>
      <transaction-meta 
        :state-changes="txnDetails.stateChanges"
        :show-events="showEvents"
      />
      <div id="nav-transaction_xdrs" class="tab-pane fade show" role="tabpanel" aria-labelledby="nav-transaction_xdrs-tab">
            <div class="alert alert-secondary">
                Decode transaction XDR's on 
                <a target="_blank" href="https://laboratory.stellar.org/#xdr-viewer?type=TransactionEnvelope&network=test">
                    Stellar Laboratory
                </a>
            </div>
            <div class="card">
                <div class="card-header">Envelope XDR</div>
                <div class="card-body">
                    <code> {{txnDetails.envelope_xdr}} </code>
                    <a class="float-end text-decoration-none cp"
                        @click="copy(txnDetails.envelope_xdr)"
                    >
                        {{ $t("transaction_stats.copy") }}
                    </a>
                </div>
            </div>
            <div class="card">
                <div class="card-header">Result XDR</div>
                <div class="card-body">
                    <code> {{txnDetails.result_xdr}} </code>
                    <a class="float-end text-decoration-none cp"
                        @click="copy(txnDetails.result_xdr)"
                    >
                        {{ $t("transaction_stats.copy") }}
                    </a>
                </div>
            </div>
            <div class="card">
                <div class="card-header">Result Meta XDR</div>
                <div class="card-body">
                    <code> {{txnDetails.result_meta_xdr}} </code>
                    <a class="float-end text-decoration-none cp"
                        @click="copy(txnDetails.result_meta_xdr)"
                    >
                        {{ $t("transaction_stats.copy") }}
                    </a>
                </div>
            </div>
            <div class="card">
                <div class="card-header">Result Meta XDR</div>
                <div class="card-body">
                    <code> {{txnDetails.fee_meta_xdr}} </code>
                    <a class="float-end text-decoration-none cp"
                        @click="copy(txnDetails.fee_meta_xdr)"
                    >
                        {{ $t("transaction_stats.copy") }}
                    </a>
                </div>
            </div>
        </div>
    </div>
  </template>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { formatDate, formatDateElapsedTime } from "@/utils/date";
import Error from "@/components/Error.vue";
import TxnStatsSkeleton from "@/components/skeletons/stats/TxnStatsSkeleton.vue";
import TransactionMeta from "@/components/TransactionMeta.vue";
import ContractAndTransactionDetailsTabs from "@/components/stats/ContractAndTransactionDetailsTabs.vue";
import OperationInfo from "@/components/operations/OperationInfo";
import Loading from "@/components/Loading.vue";
import { useI18n } from "vue-i18n";
import * as StellarSdk from 'stellar-sdk';
import {get} from 'lodash';
import {camelCaseToNormalTextWithCaps} from '@/utils/string'


export default defineComponent({
  name: "TransactionStats",
  components: {
    Loading,
    Error,
    OperationInfo,
    TxnStatsSkeleton,
    TransactionMeta,
    ContractAndTransactionDetailsTabs
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const {xdr} = StellarSdk;

    BigInt.prototype.toJSON = function () {return this.toString()}
    const txnDetails = computed(
      () => store.getters["transactions/getSelectedTxn"]
    );

    const copy = (value) => {
      navigator.clipboard.writeText(value);
    };

    const loading = computed(
      () => store.getters["transactions/getLoadingStatus"]
    );
    const error = computed(() => store.getters["transactions/getError"]);

    const txnValue = computed(() => store.getters["analytics/getTxnValue"]);

    const checkTxnValue = (txnHash, value) => {
      if (!txnValue.value.loading && !txnValue.value.error) {
        if (txnHash in txnValue.value.data) {
          return txnValue.value.data[txnHash][value].toLocaleString();
        } else {
          return 0;
        }
      } else {
        return "-";
      }
    };

    const labelData = (account) => {
      if (accountLabels.value.data[account]?.label) {
        if (
          ["Scam", "Hack"].includes(
            accountLabels.value.data[account]?.label?.name
          )
        ) {
          return {
            icon: "bi-exclamation-triangle-fill text-danger",
            tooltip: t("transaction_stats.scam_tooltip", {
              type: accountLabels.value.data[
                account
              ]?.label?.name.toUpperCase(),
            }),
          };
        }

        if (accountLabels.value.data[account]?.label.verified) {
          return {
            icon: "bi-patch-check-fill text-primary",
            tooltip: t("account_summary.verified_account"),
          };
        } else {
          return {
            icon: "bi-person-circle text-dark",
            tooltip: t("account_summary.unverified_user_defined_label"),
          };
        }
      }
    };

    const accountLabels = computed(() => store.getters["accounts/getLabels"]);
    const showEvents = computed(() => {
      let sorobanEvents = txnDetails.value.sorobanEvents;
      let events = {
        events: {},
        diagnosticEvents: []
      };

      if (sorobanEvents?.length) {
        events.events = sorobanEvents
      }

      let diagnostic = false;
      if (sorobanEvents && diagnostic) {
        events.diagnosticEvents = sorobanEvents.diagnosticEvents();
      }

      return events;
    });

    return {
      loading,
      error,
      txnDetails,
      formatDate,
      formatDateElapsedTime,
      copy,
      txnValue,
      checkTxnValue,
      accountLabels,
      labelData,
      xdr,
      showEvents,
      get,
      camelCaseToNormalTextWithCaps
    };
  },
});
</script>
