<template>
  <div class="d-lg-none">
    <div class="card mb-2" style="height: 856px">
      <div class="card-body"></div>
    </div>
    <div class="card mb-2" style="height: 300px">
      <div class="card-body"></div>
    </div>
  </div>
  <div class="d-none d-lg-block">
    <div class="card mb-2" style="height: 353px">
      <div class="card-body"></div>
    </div>
    <div class="card mb-2" style="height: 119px">
      <div class="card-body"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TxnStatsSkeleton",
  components: {},
  props: {
    rows: Number,
  },
  setup() {
    return {};
  },
});
</script>
