<template>
  <div class="d-flex mb-1">
    <div class="flex-fill">
      <div class="input-group">
        <search />
      </div>
    </div>
  </div>
  <transaction-stats />
</template>

<script>
import { defineComponent, computed, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import TransactionStats from "@/components/stats/TransactionStats.vue";
import Search from "@/components/Search.vue";

export default defineComponent({
  name: "TxnDetails",
  components: {
    TransactionStats,
    Search,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    store.dispatch("transactions/fetchTxnByHash", route.params.txn_hash);
    const txnDetails = computed(
      () => store.getters["transactions/getSelectedTxn"]
    );
    const loading = computed(
      () => store.getters["operations/getLoadingStatus"]
    );
    const error = computed(() => store.getters["operations/getError"]);

    const scroll = () => {
      window.onscroll = () => {
        let bottomOfWindow =
          window.innerHeight + window.pageYOffset + 1 >=
          document.body.scrollHeight;

        if (bottomOfWindow) {
          store.dispatch("transactions/fetchMoreOpsForTxn");
        }
      };
    };
    onMounted(scroll);
    onUnmounted(() => {
      window.onscroll = null;
    });

    return {
      txnDetails,
      loading,
      error,
    };
  },
});
</script>
